const config = {
  forwoodId: {
    APIGATEWAY: 'https://nedv8xfqg1.execute-api.eu-west-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://qh1jk8cww0.execute-api.eu-west-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'forwood-homepage-1.5.6',
    HOSTNAME: 'https://id.testing.macquarie.forwoodsafety.com',
    WEB_URL: 'https://homepage.testing.macquarie.forwoodsafety.com',
    CVC_ANDROID: 'https://play.google.com/store/apps/details?id=com.forwood.forwood_crm_2',
    CVC_IOS: 'https://apps.apple.com/vn/app/forwood-ccv/id1574565890',
    SAFETYPLUS_ANDROID: 'https://play.google.com/store/apps/details?id=com.forwood.safetyplusapp',
    SAFETYPLUS_IOS: 'https://apps.apple.com/vn/app/forwood-ccv/id6648792160',    
    UPDATE_SETTING_ACCESS_ROLE: 'ForwoodIdAdmin',
    TRANSLATION_VARIANT: ''
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
